import * as React from 'react';
import { ToastContainer } from 'react-toastify';

import { NavigationButton } from '../../store/types';
import { LEFT_PANEL_MAX_WIDTH, LEFT_PANEL_MIN_WIDTH } from '../../store/constants';

import ProtectedRoute from '../Shared/ProtectedRoute';
import asyncComponent from '../Shared/AsyncComponent';

import ModalWithText from '../Modal/ModalWithText';
import AppPage from '../../components/AppPage';
import GlobalModal from '../Shared/GlobalModal';
import Header from '../Shared/Header';
import { Switch } from 'react-router';

const Dashboard = asyncComponent(() =>
    import('../Dashboard/Dashboard').then(module => module.default)
);
const Adv = asyncComponent(() =>
    import('../Adv/Adv').then(module => module.default)
);
const Lei = asyncComponent(() =>
    import('../Lei/Lei').then(module => module.default)
);
const Edgar = asyncComponent(() =>
    import('../Edgar/Edgar').then(module => module.default)
);
const Erm = asyncComponent(() =>
    import('../Erm/Erm').then(module => module.default)
);
const Rca = asyncComponent(() =>
    import('../Rca/Rca').then(module => module.default)
);
const Users = asyncComponent(() =>
    import('../Users/Users').then(module => module.default)
);

const buttons: NavigationButton[] = [
    {route: '/dashboard', title: 'Dashboard', icon: 'home'},
    // {route: '/adv', title: 'Adv', icon: 'adv'},
    // {route: '/lei', title: 'Lei', icon: 'lei'},
    // {route: '/edgar', title: 'Edgar', icon: 'edgar'},
    {route: '/erm', title: 'Erm', icon: 'erm'},
    {route: '/rca', title: 'Rca', icon: 'rca'},
    {route: '/users', title: 'Users', icon: 'group'},
];

interface Props {
}

interface State {
    dragging: boolean;
    size?: number;
}

class LayoutWithPanel extends React.Component<Props, State> {
    size?: number;
    state = {
        size: undefined,
        dragging: false,
    };

    handleDragStart = () => {
        this.setState({
            dragging: true,
            size: undefined
        });
    }

    handleDragEnd = () => {
        this.setState({
            dragging: false,
            size: this.size
        });
    }

    handleDrag = (width: number) => {
        const middle = LEFT_PANEL_MIN_WIDTH + (LEFT_PANEL_MAX_WIDTH - LEFT_PANEL_MIN_WIDTH) / 2;
        if (width >= LEFT_PANEL_MIN_WIDTH && width <= middle) {
            this.size = LEFT_PANEL_MIN_WIDTH;
        } else if (width > middle && width <= LEFT_PANEL_MAX_WIDTH) {
            this.size = LEFT_PANEL_MAX_WIDTH;
        } else {
            this.size = undefined;
        }
    }

    render() {
        return (
            <>
                <AppPage
                    buttons={buttons}
                    minSize={LEFT_PANEL_MIN_WIDTH}
                    maxSize={LEFT_PANEL_MAX_WIDTH}
                    size={this.state.dragging ? undefined : this.state.size}
                    handleDrag={this.handleDrag}
                    handleDragStart={this.handleDragStart}
                    handleDragEnd={this.handleDragEnd}
                >
                    <Header />
                    {this.props.children}
                    <GlobalModal/>
                    <ToastContainer />

                    <Switch>
                        <ProtectedRoute path="/dashboard" component={Dashboard}/>
                        <ProtectedRoute path="/adv" component={Adv}/>
                        <ProtectedRoute path="/lei" component={Lei}/>
                        <ProtectedRoute path="/erm" component={Erm}/>
                        <ProtectedRoute path="/rca" component={Rca} />
                        <ProtectedRoute path="/users" component={Users} />
                        <ProtectedRoute path="/edgar" component={Edgar} />
                    </Switch>
                </AppPage>
                <ModalWithText/>
            </>
        );
    }
}

export default LayoutWithPanel;